// App.js
import React, { useState } from 'react';
import './App.css';

import guyImg from '../../assets/images/Ellipse_519.png'
import Xcelsz from '../../assets/images/Xcelsz.png'
import close_img from "../../assets/images/x-regular-24.png";

const App = ({setCrumb, openwaitlist}) => {
  const [activeAccordion, setActiveAccordion] = useState(null);
  const [currency, setCurrency] = useState("£");

  function closeModal() {
    console.log("Form closed");
    setCrumb();
  }
  
  const toggleAccordion = (index) => {
    setActiveAccordion(activeAccordion === index ? null : index);
  };

  const [activeSection, setActiveSection] = useState("What is Included"); // Set the default section

  const handleSidebarClick = (section) => {
    setActiveSection(section);
  };



  const [currentTestimonial, setCurrentTestimonial] = useState(0);
  const testimonials = [
    {
      name: "MR. AYI MENSAH",
      rating: 5,
      review:
        "Thanks to the Grow Your Business Bundle Plan, our leads have increased significantly, and our brand visibility is through the roof!",
      reviewsCount: 300,
      image: guyImg, // Replace with the actual image path
    },
    // Add more testimonials if needed
  ];

  const handleNext = () => {
    setCurrentTestimonial((prev) => (prev + 1) % testimonials.length);
  };

  const handlePrevious = () => {
    setCurrentTestimonial((prev) =>
      prev === 0 ? testimonials.length - 1 : prev - 1
    );
  };

  return (
    <div className="ctaScreen-overlay">
      <div className="start-container">

        <div className="closeBtn" onClick={closeModal}>
          <a href="#" onClick={closeModal}>
            <img src={close_img} alt="X" />
          </a>
        </div>
        <div style={{ margin:'0 3rem' }}>
          <h1 style={{ textAlign:'center', width:'100%' }}>Start Your Business Bundle Plan</h1>
          
          <div style={{ display:'flex', alignItems:'center', position:'absolute', left:'2rem', top:'1.5rem' }}>
            <img
              src={Xcelsz}
              alt=''
              className="logo-image"
            />
            <h2>Xcelsz</h2>

          </div>

        </div>

        <div style={{ display:'flex', alignItems:'center',margin:'0 3rem', justifyContent:'space-between', marginTop:'3rem'  }}>
          <p>Beautiful brand with start your business bundle</p>
          <header>
            <div className="converter">
              <label htmlFor="currency">Converter:</label>
              <select id="currency" onChange={(e) => setCurrency(e.target.value)}>
                <option value="£">Pounds</option>
                <option value="$">Dollars</option>
                <option value="€">Euros</option>
              </select>
              <span>{currency}1199</span>
            </div>
          </header>

        </div>
        <div className='start-wrapper'>
        <aside className="sidebar">
          
         
          <nav>
          <ul>
            <li onClick={() => handleSidebarClick("What is Included")}
              style={{ backgroundColor: activeSection === "What is Included" ? '#000' : 'transparent',  color: activeSection === "What is Included" ? '#ffff' : '#000' }}
              >1. What is Included</li>
            <li onClick={() => handleSidebarClick("Final Outcome")}
              style={{ backgroundColor: activeSection === "Final Outcome" ? '#000' : 'transparent', color: activeSection === "Final Outcome" ? '#ffff' : '#000' }}
              >2. Final Outcome</li>
            <li onClick={() => handleSidebarClick("Roadmap")}
              style={{ backgroundColor: activeSection === "Roadmap" ? '#000' : 'transparent', color: activeSection === "Roadmap" ? '#ffff' : '#000' }}
              >3. Roadmap</li>
            <li onClick={() => handleSidebarClick("Testimonials")}
              style={{ backgroundColor: activeSection === "Testimonials" ? '#000' : 'transparent', color: activeSection === "Testimonials" ? '#ffff' : '#000' }}
              >4. Testimonials</li>
            <li onClick={() => handleSidebarClick("Terms")}
              style={{ backgroundColor: activeSection === "Terms" ? '#000' : 'transparent', color: activeSection === "Terms" ? '#ffff' : '#000' }}
              >5. Terms</li>
          </ul>
        </nav>
        </aside>
        <main className="content">
          
          

          {activeSection === "What is Included" && (
            <>
              <section className="accordion">
                {accordionData.map((item, index) => (
                  <div key={index} className="accordion-item">
                    <div className="accordion-title" onClick={() => toggleAccordion(index)}>
                      <h3>{item.title}</h3>
                      <span style={{ fontSize:'1.5rem' }}>{activeAccordion === index ? '-' : '+'}</span>
                    </div>
                    {activeAccordion === index && <div className="accordion-content">{item.content}</div>}
                  </div>
                ))}
              </section>

              <div className="pagination">
                <button>&lt;</button>
                <span>1</span>
                <span>2</span>
                <button>&gt;</button>
              </div>
              {/* <button className="buy-now">Buy Now</button> */}
            </>
           )}

          {activeSection === "Final Outcome" && (
            <>
            <div className="final-outcome-container">
              <div className="score-section">
                <div className="circle-score">
                  <div className="score">95</div>
                  <div className="pointer"></div>
                </div>
                <div>
                  <div style={{ display:'flex', alignItems:'center', justifyContent:'space-between', width:'100%' }}>
                    <span>TRAFFIC</span>
                  
                    <span>20/30</span>
                  </div>
                  
                  <div className="bar">
                    <div className="filled" style={{ width: '66%' }}></div>
                  </div>
                </div>
                
                <div className="progress-bar">
                  <span>LEADS</span>
                  <div className="bar">
                    <div className="filled" style={{ width: '100%' }}></div>
                  </div>
                  <span>30/30</span>
                </div>
                <div className="progress-bar">
                  <span>CLIENT</span>
                  <div className="bar">
                    <div className="filled" style={{ width: '83%' }}></div>
                  </div>
                  <span>25/30</span>
                </div>
                <div className="progress-bar">
                  <span>ROI</span>
                  <div className="bar">
                    <div className="filled" style={{ width: '66%' }}></div>
                  </div>
                  <span>20/30</span>
                </div>
              </div>
              <div className="text-section">
                {/* <h2>At Xcelsz Solutions</h2> */}
                <p>
                At Xcelsz Solutions, We understand that growing a business requires a comprehensive strategy,
                  which is why our "FINAL OUTCOME" package delivers exactly what you need.
                </p>
                <p>
                  This done-for-you solution is designed to increase your brand visibility,
                  generate more traffic, and convert leads into loyal clients. With tailored
                  marketing strategies, you’ll see measurable results that accelerate your
                  business growth.
                </p>
                <p>
                  Whether you need a website overhaul, targeted social media campaigns, or
                  lead generation tools, our team will work tirelessly to ensure that your
                  business achieves the final outcome: sustained growth and success.
                </p>
              </div>
            </div>
           
            </>
          )}
          {activeSection === "Roadmap" && (
            <>
            <section>

            </section>
            </>
          )}
          {activeSection === "Testimonials" && (
            <>
            <div className="testimonials-container">
            <div className="text-section">
              <h2 style={{ marginBottom:'2rem' }}>What Our Clients Are Saying</h2>
              <p>
                At Xcelsz Solutions, we believe that the best endorsement comes from
                our satisfied clients.
              </p>
              <p>
                Our "Grow Your Business Bundle Plan" is designed to enhance your brand
                visibility and generate the much-needed Traffic, Leads, and Clients
                (TLC) for your business.
              </p>
              <p>
                Don’t just take our word for it—here’s what our clients have to say
                about their experiences with us:
              </p>
              <p className="reviews-count">{testimonials[currentTestimonial].reviewsCount} reviews</p>
            </div>
            <div className="client-profile">
              <div className="profile-card">
                <img
                  src={testimonials[currentTestimonial].image}
                  alt={testimonials[currentTestimonial].name}
                  className="client-image"
                />
                <h3>{testimonials[currentTestimonial].name}</h3>
                <div className="rating">
                  {Array(testimonials[currentTestimonial].rating)
                    .fill("⭐")
                    .map((star, index) => (
                      <span key={index}>{star}</span>
                    ))}
                </div>
                <p>{testimonials[currentTestimonial].review}</p>
              </div>
              <div className="pagination">
                <button onClick={handlePrevious}>&lt;</button>
                <span>{currentTestimonial + 1}</span>
                <button onClick={handleNext}>&gt;</button>
              </div>
            </div>
            </div>
            </>
          )}
          {activeSection === "Terms" && (
            <>
              <section className="accordion">
                {termsData.map((item, index) => (
                  <div key={index} className="accordion-item">
                    <div className="accordion-title" onClick={() => toggleAccordion(index)}>
                      <h3>{item.title}</h3>
                      <span style={{ fontSize:'1.5rem' }}>{activeAccordion === index ? '-' : '+'}</span>
                    </div>
                    {activeAccordion === index && <div className="accordion-content" dangerouslySetInnerHTML={{ __html: item.content }} />}
                  </div>
                ))}
              </section>
              <div className="pagination">
                <button>&lt;</button>
                <span>1</span>
                <span>2</span>
                <button>&gt;</button>
              </div>
              {/* <button className="buy-now">Buy Now</button> */}
            </>
          )}

            <button className="buy-now">Buy Now</button>

         
        </main>

        </div>
    </div>
    </div>
  );
};

const accordionData = [
  { title: "1. SEO Optimized Site", content: `At Xcelsz Solutions, we offer a 2-5 page SEO optimized website as part of our 
"Grow Your Business Bundle Plan." This tailored package ensures your business stands out 
online, driving more traffic, generating leads, and turning visitors into clients. 
<p>
Our expert team creates websites with search engine-friendly design and structure, 
focusing on keyword optimization, mobile responsiveness, fast loading speeds, 
and seamless user experience. With strong on-page SEO and strategic content, 
</p>

we boost your online visibility, helping your site rank higher on search engines like Google.

Let us handle the technical side, so you can focus on growing your business!` },
  { title: "2. Weeks Nurture Content", content: `At Xcelsz Solutions, our 'Weeks Nurture Content' is designed to keep your audience
 engaged and guide them through their buyer’s journey. Over the span of 2-5 weeks, 
we provide a series of tailored content that builds trust, nurtures leads, and drives conversions.

Each week, your prospects will receive valuable information through blog posts, emails,
 or social media updates, addressing their pain points and showcasing how your business
 can solve them. With strategic calls to action, our nurture content ensures consistent
 engagement, helping to turn potential leads into loyal customers.

Let Xcelsz Solutions handle the content creation, so you can focus on growing your business` },
  { title: "3. Weeks Hosting And Maintenance", content: "Description for Weeks Hosting And Maintenance..." },
  { title: "4. Weeks Domain Registration", content: "Description for Weeks Domain Registration..." },
  { title: "5. Insert Question here", content: "Additional content here..." },
];

const termsData = [
  { title: "1. Service Agreement", content: `By engaging with our services, you confirm that you understand and accept our methodology for increasing traffic, leads, and clients for your business.` },
  { title: "2. Payment Terms", content: `Description for Weeks Hosting And Maintenance..` },
  { title: "3. Results Disclaimer", content: "Description for Weeks Hosting And Maintenance..." },
  { title: "4. Client Responsibilities", content: "Description for Weeks Domain Registration..." },
  { title: "5. Confidentiality", content: "Additional content here..." },
];

export default App;

import { useEffect,useState } from "react"
import "../WhyXcelsz/whyxcelsz.css"
import img1 from "../../../assets/images/BusinessWhy.png"

export default function WhyXcelsz (props){
const [ accord1, setAccrord1]  = useState(false)
const [ accord2, setAccrord2]  = useState(false)
const [ accord3, setAccrord3]  = useState(false)
const [ accord4, setAccrord4]  = useState(false)
const [ accord5, setAccrord5]  = useState(false)
const [ accord6, setAccrord6]  = useState(false)
const [ accord7, setAccrord7]  = useState(false)
const [ accord8, setAccrord8]  = useState(false)

function handleclose(){
    props.setDiscover(prev => !prev)
}




function handleAccord1(){
    setAccrord1(!accord1)
}
function handleAccord2(){
    setAccrord2(!accord2)
}
function handleAccord3(){
    setAccrord3(!accord3)
}
function handleAccord4(){
    setAccrord4(!accord4)
}
function handleAccord5(){
    setAccrord5(!accord5)
}
function handleAccord6(){
    setAccrord6(!accord6)
}
function handleAccord7(){
    setAccrord7(!accord7)
}
function handleAccord8(){
    setAccrord8(!accord8)
}

    return(
        <div className="whyxcelsx-container" id="no-close">
            <div className="whyxcelsz-header">
                <p>Discover what’s included in each Solution</p>
            </div>
            <div className="whyxcelsz-accordion">
                <div className={`whyxcelsz-accordion1 ${accord1?'active':''}`}>
                    <div className="whyxcelsz-accordion1-head">
                        <p className="whyxcelsz-product-name">One Stop Solutions Platform</p>
                        <p onClick={() => handleAccord1()} className="plusOrminus">{accord1?"-":"+"}</p>
                    </div>
                    
                    <div className="whyxcelsz-accordion-text">
                        <img src={img1} alt="xcelzs solution"></img>
                        <p>Providing one-stop Solutions platforms that is an end to end solutions so that you do not need to look else where.</p>
                    </div>
                </div>
                <div className={`whyxcelsz-accordion1 ${accord2?'active':''}`}>
                    <div className="whyxcelsz-accordion1-head">
                        <p className="whyxcelsz-product-name">Better Experience</p>
                        <p onClick={() => handleAccord2()} className="plusOrminus">{accord2?"-":"+"}</p>
                    </div>
                    
                    <div className="whyxcelsz-accordion-text">
                        <img src={img1} alt="xcelzs solution"></img>
                        <p className="accord2">Experience a better way of transacting business online simply with a solutions platform that is 100% customer centric, user affordability focused, ensuring solutions people want to use.</p>
                    </div>
                </div>
                <div className={`whyxcelsz-accordion1 ${accord3?'active':''}`}>
                    <div className="whyxcelsz-accordion1-head">
                        <p className="whyxcelsz-product-name">Trusted Solutions Platform</p>
                        <p onClick={() => handleAccord3()} className="plusOrminus">{accord3?"-":"+"}</p>
                    </div>
                    
                    <div className="whyxcelsz-accordion-text">
                        <img src={img1} alt="xcelzs solution"></img>
                        <p>95-100% of our users rates xcelsz solutions excellent, saying the solutions value for money, trusted option and promotes user affordability.</p>
                    </div>
                </div>
                <div className={`whyxcelsz-accordion1 ${accord4?'active':''}`}>
                    <div className="whyxcelsz-accordion1-head">
                        <p className="whyxcelsz-product-name">Hassle - Free</p>
                        <p onClick={() => handleAccord4()} className="plusOrminus">{accord4?"-":"+"}</p>
                    </div>
                    
                    <div className="whyxcelsz-accordion-text">
                        <img src={img1} alt="xcelzs solution"></img>
                        <p className="accord4">xcelsz solutions platform enables you to sit back while it does all the work for you. You are able to outsource your needs and it matches Demand and Offer needs together, enabling fully hands-off transactions.</p>
                    </div>
                </div>
                <div className={`whyxcelsz-accordion1 ${accord5?'active':''}`}>
                    <div className="whyxcelsz-accordion1-head">
                        <p className="whyxcelsz-product-name">Time & Money Savings</p>
                        <p onClick={() => handleAccord5()} className="plusOrminus">{accord5?"-":"+"}</p>
                    </div>
                    
                    <div className="whyxcelsz-accordion-text">
                        <img src={img1} alt="xcelzs solution"></img>
                        <p>Enjoy value for money xcelsz solutions with ease with an Online dashboard to help keep track with your needs request, saving you time and money.</p>
                    </div>
                </div>
                <div className={`whyxcelsz-accordion1 ${accord6?'active':''}`}>
                    <div className="whyxcelsz-accordion1-head">
                        <p className="whyxcelsz-product-name">24/7 Support</p>
                        <p onClick={() => handleAccord6()} className="plusOrminus">{accord6?"-":"+"}</p>
                    </div>
                    
                    <div className="whyxcelsz-accordion-text">
                        <img src={img1} alt="xcelzs solution"></img>
                        <p>Get support from when ever you need it from the solutions dashboards through the chat function, or the frequently asked questions.</p>
                    </div>
                </div>
                <div className={`whyxcelsz-accordion1 ${accord7?'active':''}`}>
                    <div className="whyxcelsz-accordion1-head">
                        <p className="whyxcelsz-product-name">Web Apps</p>
                        <p onClick={() => handleAccord7()} className="plusOrminus">{accord7?"-":"+"}</p>
                    </div>
                    
                    <div className="whyxcelsz-accordion-text">
                        <img src={img1} alt="xcelzs solution"></img>
                        <p>Simplified solutions from anywhere,  meeting different needs identified in different sectors.</p>
                    </div>
                </div>
                <div className={`whyxcelsz-accordion1 ${accord8?'active':''}`}>
                    <div className="whyxcelsz-accordion1-head">
                        <p className="whyxcelsz-product-name">Safer process</p>
                        <p onClick={() => handleAccord8()} className="plusOrminus">{accord8?"-":"+"}</p>
                    </div>
                    
                    <div className="whyxcelsz-accordion-text">
                        <img src={img1} alt="xcelzs solution"></img>
                        <p>xcelsz solutions platform facilitates a safer process, trusted by many worldwide.</p>
                    </div>
                </div>
                
               

            </div>
            <div onClick={()=> handleclose() }className="whyxcelsz-close">
                <p>Close</p>
            </div>

        </div>
    )
 }
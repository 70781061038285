import { useState } from "react"
import alertimg from '../../assets/images/Confirmed-alert.png'

export default function Alert (props){

    

   function handleClose(e){
    e.preventDefault()
    props.setOpen(prev => !prev)
   }

    return(
        <>
         {props.isOpen && (
          <div
          className="alertdiv"
        >
          <div className="alert-info"
           style={{
            display : 'flex',
            justifyContent: 'center',
            alignItems:'center'
           }}>
            <img src={alertimg} alt="feedback-alert"></img>
            <div className='alert-text'>
            <h2
          style={{
            marginBottom:'0.9em',
            fontSize:'1.1em'
          }}>Error Sending Feedback</h2>
          <p>Kindly check your internet and try again</p>

            </div>
         
          </div>
         
          <button
          className="alertbtn"
          
          onClick={handleClose}
          >X</button>
        </div>)
        }
            
        
        </>
    )
}
import React, {useEffect, useState} from "react";
import WaitList from "../../../components/features/waitlist";
import Screens from "../../../components/cta/Screens";

import Test from '../../../components/jounery/App'
import PrimaryButton from "../../../assets/images/Primary_Button.png"

function calculateTimeLeft() {
    const year = new Date().getFullYear();
    const difference = +new Date(`2024-08-1`) - +new Date();
  
   
    let timeLeft = {};
  
    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60)
      };
    }
    return timeLeft;
}
  

function DigitalSection(params) {
  const [myCrumb , setMyCrumb] = useState(false)
  const [ctaVisibility , setCtaVisibility] = useState(false)


    //functionality
  //second section functionality

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const id = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => {
      clearTimeout(id);
    };
  }); 



  const pricingData = [
    {
      title: 'Increase your brand visibility with Grow your Business Bundle Plan',
      price: '£2199',
      features: [
        '2-5 Pages SEO optimized Site',
        'Free 52 Weeks Nurture Content',
        'Free 52 Weeks Socials Content',
        'Free 52 Weeks Hosting & Maintenance',
        'Free 52 Weeks Free Registration',
      ],
      rating: 5.0,
      reviews: 200,
    },
    {
      title: 'Make your brand a regular stop with Start your Business Bundle Plan',
      price: '£1199',
      features: [
        '1-2 Pages SEO optimized Site',
        'Free 52 Weeks Nurture Content',
        'Free 52 Weeks Socials Content',
        'Free 52 Weeks Hosting & Maintenance',
        'Free 52 Weeks Domain Registration',
      ],
      rating: 5.0,
      reviews: 200,
    },
    {
      title: 'Improve your brand visibility with Optimize your Business Bundle Plan',
      price: '£1999',
      features: [
        '2-3 Pages SEO optimized redesign Site',
        'Free 52 Weeks Nurture Content',
        'Free 52 Weeks Socials Content',
        'Free 52 Weeks Hosting & Maintenance',
        'Free 52 Weeks Domain Registration',
      ],
      rating: 5.0,
      reviews: 200,
    },
    {
      title: 'Increase your Leads Conversion with Scale your Business Bundle Plan',
      price: '£6499',
      features: [
        '1-10+ Pages  SEO Optimized Site, App',
        'Free 52 Weeks Nurture Content',
        'Free 52 Weeks Socials Content',
       'Free 52 Weeks Hosting & Maintenance',
       'Free 12 Weeks 1-2-Many Agile Coaching'

      ],
      rating: 4.8,
      reviews: 150,
    },
    // {
    //   title: 'Expand with Scale your Business Bundle Plan',
    //   price: '£2499',
    //   features: [
    //     '5-10 Pages SEO optimized Site',
    //     'Free 52 Weeks Socials Content',
    //     'Priority Hosting & Maintenance',
    //     'Advanced Domain Registration',
    //   ],
    //   rating: 5.0,
    //   reviews: 300,
    // },
  ];


  const [currentIndex, setCurrentIndex] = useState(0);
  const itemsPerPage = 3;

  // Function to handle dot click
  const handleDotClick = (index) => {
    setCurrentIndex(index * itemsPerPage);
  };

  const totalPages = Math.ceil(pricingData.length / itemsPerPage);




    return(
      <>
      {myCrumb && <WaitList feedback={myCrumb} setCrumb={() => setMyCrumb(!myCrumb)}/>}
      {ctaVisibility && <Test setCrumb={() => setCtaVisibility(!ctaVisibility)} openwaitlist ={() => setMyCrumb(!myCrumb)}  />}

        <div className="digital-section">
        <div className="layover">
          <div className="mywrapper">
            <div className="wrapper-left">
              <div className="firstElem">Featured</div>
              <div className="parentElem">
                <div className="childf">
                Advance your business with <br /> Xcelsz Solution’s Bundle Plans
                  {/* Grow with our Digital Solutions */}
                </div>
                {/* <span href="#">Join Oish Waiting List</span> */}
              </div>
            </div>
            {/* <div className="countme">
              <div className="countme-left">Coming Soon</div>
              <div className="countme-right">
                <div className="countdown-col">
                  <h3>Days</h3>
                  <span id="oishDay">-1</span>
                </div>
                <div className="countdown-col">
                  <h3>Hrs</h3>
                  <span id="oishHr">-19</span>
                </div>
                <div className="countdown-col">
                  <h3>Min</h3>
                  <span id="oishMin">-50</span>
                </div>
                <div className="countdown-col">
                  <h3>Sec</h3>
                  <span id="oishSec">-33</span>
                </div>
              </div>
            </div> */}
          </div>
        </div>
        <div className="main-section">
          {/* <div className="myheader">Grow with our Digital Solutions</div> */}
          <div className="childs">
            {/* <div className="card0">
              <div className="caption">
                <p>Increase your brand visibility with Grow your Business Bundle Plan</p>
              </div>
              <div className="mid">
                <p>Coming Soon</p>
                <div className="countdown-row">
                  <div className="countdown-col">
                    <h3>Days</h3>
                    <span id="yatmrDay">{timeLeft.days}</span>
                  </div>
                  <div className="countdown-col">
                    <h3>Hrs</h3>
                    <span id="yatmrHr">{timeLeft.hours}</span>
                  </div>
                  <div className="countdown-col">
                    <h3>Min</h3>
                    <span id="yatmrMin">{timeLeft.minutes}</span>
                  </div>
                  <div className="countdown-col">
                    <h3>Sec</h3>
                    <span id="yatmrSec">{timeLeft.seconds}</span>
                  </div>
                </div>
              </div>
              <div className="foot">
              <a href="#"                 
                 className="details"
                  onClick={() => {setMyCrumb( (prev) => !prev)
                    console.log(myCrumb,'UYATMR list button clicked')
                    }
                  }
                  >
                  <span>Join iXcelsz Waitlist</span>
                </a>
              </div>
            </div>
            <div className="card0">
              <div className="caption">
                <p>Make your brand a regular stop with Start your Business Bundle Plan</p>
              </div>
              <div className="mid">
                <p>Coming Soon</p>
                <div className="countdown-row">
                  <div className="countdown-col">
                    <h3>Days</h3>
                    <span id="yatmrDay">{timeLeft.days}</span>
                  </div>
                  <div className="countdown-col">
                    <h3>Hrs</h3>
                    <span id="yatmrHr">{timeLeft.hours}</span>
                  </div>
                  <div className="countdown-col">
                    <h3>Min</h3>
                    <span id="yatmrMin">{timeLeft.minutes}</span>
                  </div>
                  <div className="countdown-col">
                    <h3>Sec</h3>
                    <span id="yatmrSec">{timeLeft.seconds}</span>
                  </div>
                </div>
              </div>
              <div className="foot">
              <a href="#"
                className="details"
                onClick={() => {setMyCrumb( (prev) => !prev)
                console.log(myCrumb,'iMarketer list button clicked')
                }
              }
                >
                  <span>Join iMarketer Waitlist</span>
                </a>
              </div>
            </div>
            <div className="card0">
              <div className="caption">
                <p>Scale your business income with iPartner</p>
              </div>
              <div className="mid">
                <p>Coming Soon</p>
                <div className="countdown-row">
                  <div className="countdown-col">
                    <h3>Days</h3>
                    <span id="yatmrDay">{timeLeft.days}</span>
                  </div>
                  <div className="countdown-col">
                    <h3>Hrs</h3>
                    <span id="yatmrHr">{timeLeft.hours}</span>
                  </div>
                  <div className="countdown-col">
                    <h3>Min</h3>
                    <span id="yatmrMin">{timeLeft.minutes}</span>
                  </div>
                  <div className="countdown-col">
                    <h3>Sec</h3>
                    <span id="yatmrSec">{timeLeft.seconds}</span>
                  </div>
                </div>
              </div>
              <div className="foot">
              <a href="#"
                  className="details"
                  onClick={() => {setMyCrumb( (prev) => !prev)
                  console.log(myCrumb,'iPartner list button clicked')
                  }
                }
                >
                  <span>Join iPartner Waitlist</span>
                </a>
              </div>
            </div>
            <div className="card0">
              <div className="caption">
                <p>Maximise measurable Value  with Oish</p>
              </div>
              <div className="mid">
                <p>Coming Soon</p>
                <div className="countdown-row">
                  <div className="countdown-col">
                    <h3>Days</h3>
                    <span id="yatmrDay">{timeLeft.days}</span>
                  </div>
                  <div className="countdown-col">
                    <h3>Hrs</h3>
                    <span id="yatmrHr">{timeLeft.hours}</span>
                  </div>
                  <div className="countdown-col">
                    <h3>Min</h3>
                    <span id="yatmrMin">{timeLeft.minutes}</span>
                  </div>
                  <div className="countdown-col">
                    <h3>Sec</h3>
                    <span id="yatmrSec">{timeLeft.seconds}</span>
                  </div>
                </div>
              </div>
              <div className="foot">
              <a href="#"
                  className="details"
                  onClick={() => {setMyCrumb( (prev) => !prev)
                  console.log(myCrumb,'Oish list button clicked')
                  }
                }
                >
                  <span>Join Oish Waitlist</span>
                </a>
              </div>
            </div> */}

      {/* {pricingData.map((plan, index) => (
        <div key={index} className="pricing-card">
          <h2>{plan.title}</h2>
          <div className="price">{plan.price}</div>
          <ul className="features">
            {plan.features.map((feature, idx) => (
              <li key={idx}>{feature}</li>
            ))}
          </ul>
          <div className="rating">
            <span>{plan.rating} ★★★★★</span>
            <span>{plan.reviews} Reviews</span>
          </div>
          <button className="start-btn">Start Now</button>
        </div>
      ))} */}

{pricingData.slice(currentIndex, currentIndex + itemsPerPage).map((plan, index) => (
         <div
         key={index}
         className={`card0 ${index === currentIndex ? 'active' : ''}`}
         style={{ display: index === currentIndex ? 'block' : '' }}
       >
       {/* <div className="card0"> */}
              <div className="caption">
                <p>{plan.title}</p>
                <div className="price">{plan.price}</div>
              </div>
              <div className="mid">
              <ul className="features">
                {plan.features.map((feature, idx) => (
                  <li key={idx}>{feature}</li>
                ))}
              </ul>
              <div className="rating">
            <span>{plan.rating} <span style={{ color:'#ffd700' }}>★★★★★</span></span>
            <span>{plan.reviews} Reviews</span>
          </div>
              </div>
              <div className="foot">
              {/* <a href="#"                 
                 className="details"
                  onClick={() => {setMyCrumb( (prev) => !prev)
                    console.log(myCrumb,'UYATMR list button clicked')
                    }
                  }
                  >
                  <span>Join iXcelsz Waitlist</span>
                </a> */}

                <button className="start-btn" onClick={() => {setCtaVisibility( (prev) => !prev)
                }
              }>Start Now</button>
              </div>
            </div>
      ))}

      {/* Dot Navigation */}
      
           
          </div>

          {/* <div className="dot-navigation">
        {pricingData.map((_, index) => (
          <span
            key={index}
            className={`dot ${index === currentIndex ? 'active' : ''}`}
            onClick={() => handleDotClick(index)}
          ></span>
        ))}
      </div> */}

      <div className="dot-navigation">
        {Array.from({ length: totalPages }).map((_, index) => (
          <span
            key={index}
            className={`dot ${currentIndex / itemsPerPage === index ? 'active' : ''}`}
            onClick={() => handleDotClick(index)}
          ></span>
        ))}
      </div>

          {/* <div className="explore-more">
            <a
              href="#"
              // href="mailto:info@xcelsz.com&subject=Explore%20Solutions%20with%20Xcelsz"
              // target="_blank"
              onClick={() => {setCtaVisibility( (prev) => !prev)
                }
              }
            >
              Explore All Solutions
            </a>
          </div> */}
        </div>
      </div>
      </>
    )
}


export default DigitalSection;